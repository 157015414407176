<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-toolbar class="primary" tag="div" flat dark tile>
      <v-autocomplete
        class="mx-1"
        :label="'Filter by ' + getpatientN + 's'"
        :items="patients"
        v-model="bodyreq.patientUuid"
        item-text="fullname"
        item-value="uuid"
        style="max-width: 300px"
        prepend-inner-icon="mdi-account-search"
        clearable
        hide-details
        outlined
        @change="filterfirstPayments"
        @click:clear="resetFilter('patient')"
        dense
      ></v-autocomplete>
      <v-autocomplete
        class="mx-1"
        label="Filter by Sellers"
        :items="workers"
        v-model="bodyreq.sellerUuid"
        item-text="fullname"
        item-value="uuid"
        style="max-width: 300px"
        prepend-inner-icon="mdi-account-search"
        clearable
        @change="filterfirstPayments"
        @click:clear="resetFilter('employees')"
        hide-details
        outlined
        dense
      ></v-autocomplete>
      <v-row class="mt-3" no-gutters>
        <v-col class="mt-3">
          <ma-date-picker
            :datepickerProps="{
              'no-title': true,
            }"
            v-model="dateFrom"
            label="From"
            past
          />
        </v-col>
        <v-col class="ml-2 mt-3">
          <ma-date-picker
            :datepickerProps="{
              'no-title': true,
            }"
            past
            v-model="dateTo"
            label="To"
          />
        </v-col>
      </v-row>
      <template v-if="validClean">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="ml-2"
              elevation="0"
              small
              rounded
              depressed
              fab
              v-on="on"
              v-bind="attrs"
              @click="resetFilter('custom-select')"
            >
              <v-icon>mdi-backup-restore</v-icon>
            </v-btn>
          </template>
          <span>Clear filters</span>
        </v-tooltip>
      </template>
    </v-toolbar>

    {{/* Interval indicator */}}
    <v-layout class="pa-4" row>
      <v-layout column>
        <div class="mr-2 text-capitalize">
          {{ filterRanges.replace("-", " ") }}
        </div>

        <v-layout column class="text-body-1 text--secondary">
          <div>
            From:
            <span class="text-caption">{{
              prettyDateShow(bodyreq.dates[0])
            }}</span>
          </div>
          <div>
            To:
            <span class="text-caption">{{
              prettyDateShowTo(bodyreq.dates[1])
            }}</span>
          </div>
        </v-layout>
      </v-layout>
      <template v-if="bodyreq.employeeUuid != null">
        <v-layout class="mt-3" row>
          <v-flex xs2>
            <div>Total:</div>
            <span class="text-caption">
              {{ prettyMinutes(canthours) }}
            </span>
          </v-flex>
          <v-flex xs4>
            <div>Current Salary (usd/h):</div>
            <span class="text-caption">
              {{ payXhours | currency }}
            </span>
          </v-flex>
          <v-flex xs4>
            <div>Total Pay:</div>
            <span class="text-caption">
              {{ totalpay | currency }}
            </span>
          </v-flex>
        </v-layout>
      </template>
    </v-layout>
    <v-divider />

    <v-data-table
      :headers="headers"
      :options.sync="options"
      :server-items-length="totalfirst"
      single-expand
      show-expand
      :expanded.sync="expanded"
      :items="_firstconfirmpays"
      :footer-props="footerProps"
      :loading="loading"
      loading-text="Loading... Please wait"
      item-key="pos"
    >
      <template v-slot:[`item.type`]="{ item }">
        {{ typesShow(getType(item)) }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ getAmount(item) | currency }}
      </template>
      <template v-slot:[`item.paymentDate`]="{ item }">
        {{ prettyDate(item.payment.createAt) }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="d-flex ma-4">
            <div class="col col-sm-3">
              <div>
                <v-icon class="mr-2">mdi-cash-usd</v-icon>
                <strong>{{ typesShow(getType(item)) }}: </strong>
                <span
                  :class="
                    getStatus(item) == 'CONFIRMED' ? 'green--text' : 'red--text'
                  "
                >
                  {{
                    getStatus(item) == "CONFIRMED" ? getStatus(item) : "FAIL!"
                  }}</span
                >
              </div>
              <div>
                <v-icon class="mr-2">mdi-account</v-icon>
                <strong>Employee: </strong>
                <span>{{ item.seller_name }}</span>
              </div>
              <div>
                <v-icon class="mr-2">mdi-medical-bag</v-icon>
                <strong>Patient: </strong>
                <span>{{ item.patient_name }}</span>
              </div>
            </div>

            <div class="col col-sm-3">
              <div>
                <strong>Amount: </strong>
                <span> {{ getAmount(item) | currency }}</span>
              </div>
              <div>
                <template v-if="item.procedurename">
                  <strong>Procedure: </strong>
                  <span>{{ item.procedurename }}</span>
                </template>
              </div>
            </div>
            <div class="col col-sm-4">
              <div>
                <strong>Date: </strong>
                <span> {{ prettyDate(item.payment.createAt) }}</span>
              </div>
            </div>
            <v-spacer></v-spacer>
            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mt-1"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    fab
                    small
                    @click="gotoPatient(item)"
                  >
                    <v-icon>mdi-account-box</v-icon>
                  </v-btn>
                </template>
                <span>Go to {{ getpatientN }}</span>
              </v-tooltip>
            </template>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifySuccess,
} from "@/components/Notification";

export default {
  components: { MaDatePicker },
  data() {
    return {
      options: {},
      dateTo: "",
      dateFrom: "",
      expanded: [],
      firstpayments: [],
      filterRanges: "this-year",
      filterEmployees: "",
      filterPatients: "",
      totalfirst: 0,
      loading: false,
      footerProps: { "items-per-page-options": [25, 100, 500] },
      bodyreq: {
        range: { limit: 25, offset: 0 },
        patientUuid: null,
        sellerUuid: null,

        dates: ["", ""],
      },
    };
  },
  computed: {
    ...mapGetters(["getSellerN", "getpatientN"]),
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState("crmEmployeeModule", ["workers"]),
    headers() {
      return [
        { text: "Type", value: "type", sortable: false },
        {
          text: this.getpatientN + " name",
          value: "patient_name",
          sortable: false,
        },
        { text: "Amount $", value: "amount", sortable: false, align: "end" },
        { text: "Payment date", value: "paymentDate", sortable: false },
        {
          text: this.getSellerN + " name",
          value: "seller_name",
          sortable: false,
        },
        { text: "", value: "data-table-expand", sortable: false },
      ];
    },

    validClean() {
      if (
        (this.dateFrom != null &&
          this.dateFrom != undefined &&
          this.dateFrom != "") ||
        (this.dateTo != null && this.dateTo != undefined && this.dateTo != "")
      ) {
        return true;
      }
      return false;
    },
    _firstconfirmpays() {
      if (this.firstpayments.length == 0) {
        return [];
      }
      const filt = this.firstpayments.filter(
        (f) => f.payment.status == "CONFIRMED"
      );
      const mapp = filt.map((f, i) => {
        let temp = f;
        temp = { ...temp, pos: i };
        return temp;
      });

      return mapp;
    },

    intervarThisYear() {
      const from = moment().month(0).date(1).startOf("day").utc().toISOString();
      const to = moment().utc().toISOString();

      return {
        date1: from,
        date2: to,
      };
    },
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.filterfirstPayments();
      }
    },
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.filterRanges = "custom-select";
        this.filterfirstPayments();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.filterRanges = "custom-select";
        this.filterfirstPayments();
      }
    },
  },
  methods: {
    ...mapActions("crmConfigurationsModule", [" actFirstPayments"]),
    ...mapMutations("crmMedicFormModule", ["mutProcedure"]),
    ...mapMutations(["mutReqRange"]),
    ...mapMutations([
      "crmConfigurationsModule",
      ["mutSetLoad", "mutSetFirstPayments"],
    ]),

    prettyDateShow(date) {
      if (date === null || date === "") return "";
      /*   if (this.filterRanges == "custom-select") {
        return moment(date)
          .utc()
          .tz("America/New_York")
          .local()
          .format("dddd D MMMM YYYY, h:mm:ss a");
      } */
      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    prettyDateShowTo(date) {
      if (date === null || date === "") return "";
      /*  if (this.filterRanges == "custom-select") {
        return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
      } */

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    async filterfirstPayments() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 25;
      let body = {
        dateRange: {
          date1: this.bodyreq.dates[0],
          date2: this.bodyreq.dates[1],
        },
        range: this.bodyreq.range,
        patientUuid: this.bodyreq.patientUuid,
        sellerUuid: this.bodyreq.sellerUuid,
      };

      body.range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };

      switch (this.filterRanges) {
        case "this-year":
          body.dateRange = {
            date1: this.intervarThisYear.date1,
            date2: this.intervarThisYear.date2,
          };
          break;

        case "custom-select":
          body.dateRange = {
            date1: moment(this.dateFrom).startOf("day").utc().toISOString(),
            date2: moment(this.dateTo).endOf("day").utc().toISOString(),
          };
          break;
      }
      this.bodyreq.dates[0] = body.dateRange.date1;
      this.bodyreq.dates[1] = body.dateRange.date2;
      this.expanded = [];
      this.mutReqRange(body.range);
      body = this.cleanNull(body);

      this.loading = true;

      getAPI.post("/sprocedures/getFirstPayments", body).then((res) => {
        this.totalfirst = res.data.count;
        this.firstpayments = res.data.result;
        this.loading = false;
      });
    },

    resetFilter(value) {
      switch (value) {
        case "custom-select":
          this.filterRanges = "this-year";
          this.dateFrom = "";
          this.dateTo = "";
          this.filterfirstPayments();
          break;
        case "employees":
          this.filterEmployees = "";
          this.bodyreq.sellerUuid = null;
          this.filterfirstPayments();
          break;
        case "patient":
          this.filterPatients = "";
          this.bodyreq.patientUuid = null;
          this.filterfirstPayments();
          break;
        default:
          this.filterEmployees = "";
          this.filterPatients = "";
          this.filterRanges = "this-year";
          this.bodyreq = {
            range: { limit: 25, offset: 0 },
            patientUuid: null,
            sellerUuid: null,
            dates: ["", ""],
          };
          this.filterfirstPayments();
          break;
      }
    },
    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },

    getType(item) {
      if (item.payment == null || item.payment == undefined) {
        return "-";
      }
      return item.payment.type;
    },
    typesShow(type) {
      if (type == "External_Card_Reader") {
        return "Stripe Card Reader";
      }
      if (type == "Stripe") {
        return "Card";
      }
      return type;
    },

    getAmount(item) {
      if (item.payment == null || item.payment == undefined) {
        return 0;
      }
      return item.payment.amount;
    },
    getStatus(item) {
      if (item.payment == null || item.payment == undefined) {
        return "-";
      }
      return item.payment.status;
    },

    async gotoPatient(item) {
      const procedure = (
        await getAPI("/patient/getProcedure/" + item.procedureId)
      ).data;

      this.mutProcedure(procedure);
      this.$router.push({
        name: "Patient ddetails",
        params: { uuid: item.patient_uuid },
      });
    },

    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
};
</script>
<style lang=""></style>
